<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
    >
      <work-order-card />
    </b-col>
    <b-col
      cols="12"
      md="8"
    >
      <validation-observer ref="simpleRules">
        <item-form :rpa-data-item="rpaDataItem" />
        <save-button
          :save-action="submitForm"
          :is-disabled="submitStatus"
        />
      </validation-observer>
    </b-col>
  </b-row>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ItemForm from '@/views/Service_work_orders/Orders/ItemForm.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import WorkOrderCard from '@/views/Service_work_orders/Orders/WorkerView/WorkOrderCard.vue'

export default {
  name: 'Add',
  components: {
    ItemForm,
    SaveButton,
    ValidationObserver,
    WorkOrderCard,
    BRow,
    BCol,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    rpaDataItem() {
      return this.$store.getters['serviceRPA/dataItem']
    },
    dataItem() {
      return this.$store.getters['serviceWorkOrders/dataItem']
    },
    saveData() {
      return this.$store.getters['serviceWorkOrders/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
        this.$router.push(`/service/work-orders/worker-view/${val.id}`)
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('serviceWorkOrders/RESET_DATA_ITEM')
    localize('tr')
    this.getDataItem()
    this.getWorkers()
  },
  methods: {
    getDataItem() {
      this.$store.dispatch('serviceRPA/getDataItem', this.$route.params.id)
        .then(response => {
          if (response) {
            this.dataItem.work_no = response.Is_Emri_No
            this.dataItem.id_tbl_IsEmirleri = response.id
          }
        })
    },
    getWorkers() {
      this.$store.dispatch('swoWorkers/getDataList', {
        select: [
          'com_service_workers.id AS id',
          'com_service_workers.fullname AS title',
          'com_service_workers.subtitle AS subtitle',
          'com_service_sections.title AS section',
          'com_location.title AS location',
        ],
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.submitStatus = true
          this.$store.dispatch('serviceWorkOrders/saveData', this.dataItem)
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
