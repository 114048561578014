<template>
  <b-nav-item-dropdown
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationCount"
        badge-classes="bg-danger"
        class="text-primary"
        icon="BellIcon"
        size="21"
      />
      <span class="ml-1 font-weight-bold text-primary">
        Bildirimlerim
      </span>
    </template>
    <template v-if="loading">
      <div class="text-center p-2">
        <b-spinner />
      </div>
    </template>
    <template v-else>
      <!-- Header -->
      <li class="dropdown-menu-header">
        <div class="dropdown-header d-flex">
          <h4 class="notification-title mb-0 mr-auto">
            Bildirimlerim
          </h4>
          <b-badge
            pill
            variant="light-warning"
          >
            {{ notificationCount }} Okunmamış
          </b-badge>
        </div>
      </li>

      <!-- Notifications -->
      <vue-perfect-scrollbar
        v-if="notificationCount > 0"
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        tagname="li"
      >
        <!-- Account Notification -->
        <b-link
          v-for="notification in notifications"
          :key="notification.id"
          @click="goToPage(notification)"
        >
          <b-media>
            <template #aside>
              <b-avatar variant="light-danger">
                <FeatherIcon
                  icon="BellIcon"
                />
              </b-avatar>
            </template>
            <p class="media-heading">
              <span class="font-weight-bolder text-primary">
                {{ notification.title }}
              </span>
            </p>
            <small
              class="notification-text"
            >
              <span>
                {{ notification.body }}
              </span>
            </small>
          </b-media>
        </b-link>
      </vue-perfect-scrollbar>
      <div v-else>
        <b-alert
          show
          variant="info"
          class="m-2"
        >
          <div class="alert-body text-center">
            Hiç okunmamış bildiriminiz yok. 👏
          </div>
        </b-alert>
      </div>
      <!-- Cart Footer -->
      <li class="dropdown-menu-footer"><b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="danger"
        block
        to="/notifications"
      >
        <FeatherIcon icon="EyeIcon" />
        Tümünü Görüntüle
      </b-button>
      </li>
    </template>
  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BBadge, BMedia, BLink, BAvatar, BButton, BSpinner, BAlert,
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    BLink,
    BAvatar,
    VuePerfectScrollbar,
    BButton,
    BSpinner,
    BAlert,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      lastCount: 0,
      loading: true,
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
        wheelPropagation: false,
      },
    }
  },
  computed: {
    notifications() {
      return this.$store.getters['notifications/dataList']
    },
    notificationCount() {
      return this.$store.getters['notifications/dataCount']
    },
  },
  created() {
    this.getNotifications()
  },
  mounted() {
    // Vue bileşeni oluşturulduğunda otomatik metodunuz başlatılabilir.
    this.autoMethod()
  },
  methods: {
    autoMethod() {
      setInterval(() => {
        this.getNotifications()
      }, 600000)
    },
    goToPage(data) {
      if (!data.is_read) {
        this.$store.dispatch('notifications/saveData', {
          id: data.id,
          read_item: 1,
        }).then(response => {
          if (response) {
            this.$store.dispatch('notifications/getDataList', {
              where: {
                'com_notifications.is_read': null,
              },
              limit: 10,
              start: 0,
            }).then(resp => {
              if (resp) {
                if (data.item_id) {
                  window.location.href = data.route + data.item_id
                }
              }
            })
          }
        })
      } else if (data.item_id) {
        window.location.href = data.route + data.item_id
      }
    },
    showAlert(count) {
      if (count > 0) {
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Yeni Bildirim!',
            icon: 'CheckIcon',
            variant: 'warning',
            text: `☕️ Yeni ${count} adet bildirim var.`,
          },
        })
      }
    },
    getNotifications() {
      this.loading = true
      this.$store.dispatch('notifications/getDataList', {
        where: {
          'com_notifications.is_read': null,
        },
        limit: 10,
        start: 0,
      }).then(response => {
        if (response) {
          const alertCount = Number(response.count) - Number(this.lastCount)
          this.showAlert(alertCount)
          this.lastCount = response.count
          this.loading = false
        }
      })
    },
  },
}
</script>
<style scoped>
.notification-text span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 320px !important;
  display: block;
}
</style>
