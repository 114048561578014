<template>
  <validation-observer ref="simpleRules">
    <b-form
      class="p-2"
    >
      <b-row>
        <b-col md="12">
          <customerType />
        </b-col>
        <b-col
          v-if="customer.id_com_customer_type === '2'"
          md="12"
        >
          <tax-number />
          <companyName />
        </b-col>
        <b-col
          v-if="customer.id_com_customer_type === '1'"
          md="12"
        >
          <tckn />
          <nameSurname />
        </b-col>
        <b-col md="12">
          <phone :is-readonly="true" />
        </b-col>
        <b-col
          v-if="!nonKvkkGroup.includes(userData.id_com_user_type)"
          cols="12"
        >
          <new-kvkk />
        </b-col>
        <b-col md="12">
          <email :is-required="false" />
        </b-col>
      </b-row>
      <!-- Form Actions -->
      <div class="d-flex mt-2">
        <saveButton
          :kvkk-require="!nonKvkkGroup.includes(userData.id_com_user_type)"
          :action-methods="submitForm"
        />
      </div>
    </b-form>
  </validation-observer>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import {
  BForm, BRow, BCol,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import saveButton from '@/views/Customers/forms/SaveButton.vue'
import Tckn from '@/views/Customers/forms/Tckn.vue'
import TaxNumber from '@/views/Customers/forms/TaxNumber.vue'
import nameSurname from '@/views/Customers/forms/NameSurname.vue'
import companyName from '@/views/Customers/forms/CompanyName.vue'
import customerType from '@/views/Customers/forms/CustomerType.vue'
import phone from '@/views/Customers/forms/Phone.vue'
import email from '@/views/Customers/forms/Email.vue'
import NewKvkk from '@/views/Customers/forms/NewKvkk.vue'

export default {
  name: 'YeastarCustomerCard',
  components: {
    BForm,
    BRow,
    BCol,
    saveButton,
    Tckn,
    TaxNumber,
    nameSurname,
    companyName,
    customerType,
    phone,
    email,
    NewKvkk,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    refreshData: {
      type: Function,
      required: false,
    },
    phone: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      nonKvkkGroup: ['7', '8'],
      locale: 'tr',
      options: {
        phone: {
          phone: true,
          phoneRegionCode: 'TR',
        },
      },
      submitStatus: false,
    }
  },
  computed: {
    customer() {
      const data = this.$store.getters['customers/getCustomer']
      data.phone = this.phone
      return data
    },
    saveStatus() {
      return this.$store.getters['customers/getCustomerSaveStatus']
    },
    modalData() {
      return this.$store.getters['yeastar/getViewModal']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        this.formData = {
          id_com_brand: null,
          id_com_location: null,
          sdate: null,
          edate: null,
          count: null,
        }
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'İşleme devam et',
          id: val.id,
        }
        this.sweetAlert(config)
        this.refreshData()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Tekrar Dene',
          id: val.id,
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.customer.submitStatus = false
    },
  },
  created() {
    localize(this.locale)
    this.$store.commit('customers/RESET_CUSTOMER_FORM')
    this.$store.commit('customers/RESET_KVKK_SMS_RESULT')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
        preConfirm: () => {
          if (config.id) {
            // burası callback
            this.$store.dispatch('customers/getData', {
              where: {
                'com_customer.phone': this.customer.phone,
              },
              array: false,
            }).then(() => {
              this.modalData.customerOnly = true
            })
          }
        },
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitStatus = true
          this.customer.submitStatus = true
          this.$store.dispatch('customers/customerSave', this.customer)
        }
      })
    },
  },
}
</script>
