<template>
  <b-card title="İş Emri Bilgileri">
    <b-list-group>
      <b-list-group-item>
        <div>
          <div class="font-weight-bolder text-primary">
            İş Emri No
          </div>
          <div class="text-danger font-medium-2 font-weight-bolder">
            {{ rpaDataItem.Is_Emri_No }}
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="font-weight-bolder text-primary">
            Araç Bilgisi
          </div>
          <div class="font-weight-bold">
            <div>{{ rpaDataItem.Marka }}</div>
            <div>{{ rpaDataItem.Arac_Model }}</div>
            <div>{{ rpaDataItem.Model_Yili }}</div>
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="rpaDataItem.Plaka">
        <div>
          <div class="font-weight-bolder text-primary">
            Araç Plaka
          </div>
          <div class="text-danger font-medium-2 font-weight-bolder">
            {{ rpaDataItem.Plaka }}
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item>
        <div>
          <div class="font-weight-bolder text-primary">
            Şase / Motor
          </div>
          <div>
            {{ rpaDataItem.Sase_No }}
            {{ rpaDataItem.Motor_No }}
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="rpaDataItem.Km">
        <div>
          <div class="font-weight-bolder text-primary">
            Km
          </div>
          <div>
            {{ rpaDataItem.Km | toNumber }} km
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="rpaDataItem.Islem_Tipi">
        <div>
          <div class="font-weight-bolder text-primary">
            İşlem Tipi
          </div>
          <div class="text-danger font-medium-2 font-weight-bolder">
            {{ rpaDataItem.Islem_Tipi }}
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="rpaDataItem.Randevu_Tarihi">
        <div>
          <div class="font-weight-bolder text-primary">
            Randevu Tarihi
          </div>
          <div>
            {{ rpaDataItem.Randevu_Tarihi }}
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="rpaDataItem.Lokasyon">
        <div>
          <div class="font-weight-bolder text-primary">
            Lokasyon
          </div>
          <div>
            {{ rpaDataItem.Lokasyon }}
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="rpaDataItem.Danisman">
        <div>
          <div class="font-weight-bolder text-primary">
            Danışman
          </div>
          <div>
            {{ rpaDataItem.Danisman }}
          </div>
        </div>
      </b-list-group-item>
      <b-list-group-item v-if="rpaDataItem.Musteri_Talebi">
        <div>
          <div class="font-weight-bolder text-primary">
            Müşteri Talebi
          </div>
          <div>
            {{ rpaDataItem.Musteri_Talebi }}
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </b-card>
</template>
<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'WorkOrderCard',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
  },
  computed: {
    rpaDataItem() {
      return this.$store.getters['serviceRPA/dataItem']
    },
  },
}
</script>
