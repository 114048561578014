<template>
  <b-form-group
    label="Marka"
    label-for="id_com_brand"
  >
    <validation-provider
      #default="{ errors }"
      name="Marka"
      rules="required"
    >
      <v-select
        id="id_com_brand"
        v-model="dataItem.id_com_brand"
        label="title"
        :reduce="item => item.id"
        :options="dataList"
        placeholder="Marka"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  props: {
    rpaDataItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceWorkOrders/dataItem']
    },
    dataList() {
      return this.$store.getters['brands/getBrands']
    },
  },
  created() {
    localize('tr')
    this.getBrands()
  },
  mounted() {
    this.setBrand()
  },
  methods: {
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: [
          'com_brand.id AS id',
          'com_brand.name AS title',
        ],
        where: {
          'com_brand.servicestatus': 1,
        },
      })
    },
    setBrand() {
      if (this.rpaDataItem) {
        if (this.rpaDataItem.Marka) {
          if (this.rpaDataItem.Marka === 'BCS') {
            this.dataItem.id_com_brand = '14'
          }
          if (this.rpaDataItem.Marka === 'Citroen') {
            this.dataItem.id_com_brand = '1'
          }
          if (this.rpaDataItem.Marka === 'Honda') {
            this.dataItem.id_com_brand = '2'
          }
          if (this.rpaDataItem.Marka === 'Honda Motor') {
            this.dataItem.id_com_brand = '22'
          }
          if (this.rpaDataItem.Marka === 'Hyundai') {
            this.dataItem.id_com_brand = '3'
          }
          if (this.rpaDataItem.Marka === 'Kia') {
            this.dataItem.id_com_brand = '4'
          }
          if (this.rpaDataItem.Marka === 'Mazda') {
            this.dataItem.id_com_brand = '5'
          }
          if (this.rpaDataItem.Marka === 'Mitsubishi') {
            this.dataItem.id_com_brand = '13'
          }
        }
      }
    },
  },
}
</script>
