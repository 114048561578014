<template>
  <div>
    <b-card
      title="Kullanım Kılavuzu"
    >
      <b-card-text>Aşağıdaki bağlantılara tıklayarak kılavuzlara erişebilirsiniz.</b-card-text>
      <b-list-group>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/sifir-satis-kullanim-kilavuzu.PDF"
          class="d-flex justify-content-between align-items-center"
          target="_blank"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Sıfır Satış ve Aksesuar
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/servis-kullanim-klavuzu.pdf"
          class="d-flex justify-content-between align-items-center"
          target="_blank"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Servis
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="#"
          class="d-flex justify-content-between align-items-center"
          disabled
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            İkinci El Satış
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/filo-kiralama-kullanim-kilavuzu.pdf"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Filo Kiralama
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/musteri-iliskileri-kullanim-kilavuzu.pdf"
          class="d-flex justify-content-between align-items-center"
          target="_blank"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Müşteri İlişkileri
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/basvuru-suresi-hesaplama.pdf"
          class="d-flex justify-content-between align-items-center"
          target="_blank"
        >
          <span>
            <FeatherIcon
              icon="FileTextIcon"
            />
            Müşteri İlişkileri - Başvuru Yanıt Süreleri Nasıl Hesaplanır?
          </span>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/iletisim-talep-atama.pdf"
          class="d-flex justify-content-between align-items-center"
          target="_blank"
        >
          <span>
            <FeatherIcon
              icon="FileTextIcon"
            />
            Müşteri İlişkileri - Web İletişim Talepleri Danışman Atama ve Başvuru Oluşturma
          </span>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/ip_santral_gorusme_notlari_rehberi.pdf"
          target="_blank"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Müşteri İlişkileri - IP Santral Görüşme Notları Kullanım Rehberi
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/yeastar-pop-up-izinleri.pdf"
          class="d-flex justify-content-between align-items-center"
          target="_blank"
        >
          <span>
            <FeatherIcon
              icon="FileTextIcon"
            />
            Ip Santral (Yeastar) - Pop-up izinleri nasıl ayarlanır?
          </span>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="#"
          class="d-flex justify-content-between align-items-center"
          disabled
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Yedek Parça
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/sigorta-kullanim-kilavuzu.pdf"
          class="d-flex justify-content-between align-items-center"
          target="_blank"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Sigorta
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/sigorta-yenileme-listesi-kayit-silme-rehberi.pdf"
          target="_blank"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Sigorta - Yenileme Kaydı Silme Ekranları Kullanım Klavuzu
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="#"
          class="d-flex justify-content-between align-items-center"
          disabled
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Satın Alma
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="#"
          class="d-flex justify-content-between align-items-center"
          disabled
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Gayrimenkul
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/Zapier-Kullanimi.pdf"
          target="_blank"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Pazarlama - Zapier Kullanımı
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/data-aktarim-klavuzu.pdf"
          target="_blank"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Pazarlama - Data Aktarım Klavuzu
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/izin-talep-klavuzu.pdf"
          target="_blank"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            İnsan Kaynakları - İzin Talepleri Kullanım Klavuzu
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/hasar-takip-klavuzu.pdf"
          target="_blank"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            Hasar Departmanı - Hasar Takip Modülü Kullanım Klavuzu
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
        <b-list-group-item
          href="https://inll.app/Medusa2/media/guides/Portal_yemek_menusu_toplu_aktarim_rehberi.pdf"
          target="_blank"
          class="d-flex justify-content-between align-items-center"
        >
          <p class="mb-0">
            <FeatherIcon
              icon="FileTextIcon"
            />
            İnsan Kaynakları - Portal Yemek Menüsü Toplu Aktarım Klavuzu
          </p>
          <span><FeatherIcon icon="DownloadIcon" /></span>
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BListGroup, BListGroupItem, BCardText,
} from 'bootstrap-vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BCardText,
  },
}
</script>
