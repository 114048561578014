import axiosIns from '@/libs/axios'

const ROOT_URL = '/data/service/service_work_order_sms'
export default {
  namespaced: true,
  state: {
    dataList: [],
    workSmsData: {
      data: {
        work_no: null,
        customer_name: null,
        customer_phone: null,
        Marka: null,
        Arac_Model: null,
        Sase_No: null,
        Motor_No: null,
        Danisman: null,
        Plaka: null,
      },
      smsList: [],
    },
    dataCounts: 0,
    dataItem: {
      id: null,
      message: null,
      phone: null,
      error: null,
      status: null,
      is_start: null,
      is_end: null,
      id_tbl_IsEmirleri: null,
      id_com_service_work_orders: null,
      id_com_customer: null,
      id_com_user: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    filterUsers: [],
    filterLocations: [],
    dataLoading: false,
    modalData: {
      status: false,
      data: {
        work_no: null,
        customer_name: null,
        customer_phone: null,
        Marka: null,
        Arac_Model: null,
        Sase_No: null,
        Motor_No: null,
        Danisman: null,
        Plaka: null,
      },
      smsList: [],
    },
  },
  getters: {
    workSmsData(state) {
      return state.workSmsData
    },
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    filterUsers(state) {
      return state.filterUsers
    },
    filterLocations(state) {
      return state.filterLocations
    },
    dataLoading(state) {
      return state.dataLoading
    },
    modalData(state) {
      return state.modalData
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_WORK_SMS_DATA(state, data) {
      state.workSmsData.data = data.data
      state.workSmsData.smsList = data.smsList
    },
    SET_PENDING_LIST(state, data) {
      state.dataList = data.data
      if (state.filterUsers.length === 0) {
        state.filterUsers = data.filterUsers
      }
      if (state.filterLocations.length === 0) {
        state.filterLocations = data.filterLocations
      }
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        message: null,
        phone: null,
        error: null,
        status: null,
        is_start: null,
        is_end: null,
        id_tbl_IsEmirleri: null,
        id_com_service_work_orders: null,
        id_com_customer: null,
        id_com_user: null,
      }
    },
    SET_LOADING(state, data) {
      state.dataLoading = data
    },
  },
  actions: {
    getPendingList({ commit }, params) {
      const data = JSON.stringify(params)
      commit('SET_LOADING', true)
      return axiosIns
        .post(`${ROOT_URL}/pendingList`, data)
        .then(response => {
          commit('SET_PENDING_LIST', response.data)
          commit('SET_LOADING', false)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getDataList({ commit }, params) {
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
        })
        .catch(error => console.error(error))
    },
    getData({ commit }, params) {
      return axiosIns
        .post(`${ROOT_URL}/getData`, params)
        .then(response => {
          commit('SET_WORK_SMS_DATA', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
  },
}
