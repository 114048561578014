<template>
  <b-row>
    <b-col cols="4">
      <b-card title="Müşteri Kartı">
        <b-list-group>
          <list-item
            title="Müşteri Tipi"
            :value="customer.customer_type"
          />
          <list-item
            title="Adı Soyadı"
            :value="customer.name"
          />
          <list-item
            title="Firma Adı"
            :value="customer.company_name"
          />
          <list-item
            title="Cinsiyet"
            :value="customer.gender"
          />
          <list-item
            title="Telefon"
            :value="customer.phone"
          />
          <list-item
            title="Telefon 2"
            :value="customer.phone2"
          />
          <list-item
            title="E-Posta"
            :value="customer.email"
          />
          <list-item
            title="Müşteri Notu"
            :value="customer.notes"
          />
          <list-item
            title="TC Kimlik No"
            :value="customer.tckn"
          />
          <list-item
            title="Vergi No"
            :value="customer.tax_number"
          />
          <list-item
            title="Vergi Dairesi"
            :value="customer.tax_office"
          />
          <list-item
            title="Adres"
            :value="addressLine"
          />
          <list-item
            title="Sektör"
            :value="customer.sector"
          />
          <list-item
            title="Meslek"
            :value="customer.job"
          />
          <list-item
            title="Temas Tipi"
            :value="customer.meet"
          />
          <list-item
            title="KVKK"
            :value="kvkk"
          />
        </b-list-group>
        <b-alert
          v-if="customer.logo_id"
          show
          variant="info"
          class="mt-1"
        >
          <div class="alert-body text-center">
            <div>
              <feather-icon icon="InfoIcon" />
            </div>
            Müşteriye ait muhasebe departmanında cari hesap bulunmaktadır. Yapacağınız değişiklikleri muhasebe departmanına bildirmeniz gerekebilir.
          </div>
        </b-alert>
        <div class="mt-2 text-center">
          <b-button
            variant="primary"
            :to="'/customers/edit/' + customer.id"
          >
            Bilgileri Güncelle
          </b-button>
        </div>
      </b-card>
    </b-col>
    <b-col cols="8">
      <b-card title="Müşteri Geçmişi">
        <customer-history />
      </b-card>
      <related-person-view :customer-id="customer.id" />
      <b-card
        title="Hızlı İşlemler"
      >
        <div>
          <b-row class="d-flex justify-content-center">
            <b-col
              cols="12"
              md="6"
              class="mb-1"
            >
              <b-button
                variant="primary"
                block
                @click="$router.push('/interviews/add/' + customer.id)"
              >
                <feather-icon
                  icon="MessageSquareIcon"
                  class="mr-50"
                />
                Görüşme Notu
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-1"
            >
              <b-button
                variant="primary"
                block
                @click="$router.push('/crm/add/' + customer.id)"
              >
                <feather-icon
                  icon="PhoneCallIcon"
                  class="mr-50"
                />
                Başvuru
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-1"
            >
              <b-button
                variant="primary"
                block
                @click="$router.push('/service/add/' + customer.id)"
              >
                <feather-icon
                  icon="SettingsIcon"
                  class="mr-50"
                />
                Servis Randevusu
              </b-button>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-1"
            >
              <b-button
                variant="primary"
                block
                @click="$router.push('/cars/add/' + customer.id)"
              >
                <feather-icon
                  icon="DiscIcon"
                  class="mr-50"
                />
                Araç Kartı
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
            <b-col
              cols="12"
              md="12"
            >
              <marketing-sms-card :id-com-customer="customer.id" />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BAlert,
  BButton, BCard, BCol, BListGroup, BRow,
} from 'bootstrap-vue'
import ListItem from '@/views/Customers/view/ListItem.vue'
import CustomerHistory from '@/views/Customers/view/History.vue'
import RelatedPersonView from '@/views/Customers/view/RelatedPersonView.vue'
import MarketingSmsCard from '@/views/Marketing/Marketing_sms/Index.vue'

export default {
  name: 'CustomerView',
  components: {
    MarketingSmsCard,
    RelatedPersonView,
    BAlert,
    BRow,
    BCol,
    BCard,
    BListGroup,
    BButton,
    ListItem,
    CustomerHistory,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
    addressLine() {
      const addon = []
      if (this.customer.address) {
        addon.push(this.customer.address)
      }
      if (this.customer.city) {
        addon.push(this.customer.city)
      }
      if (this.customer.district) {
        addon.push(this.customer.district)
      }
      if (this.customer.region) {
        addon.push(this.customer.region)
      }
      if (this.customer.neighborhood) {
        addon.push(this.customer.neighborhood)
      }
      return addon.join('/')
    },
    kvkk() {
      if (this.customer.kvkk) {
        return `${this.customer.kvkk_type} - ${this.moment(this.customer.kvkkdate).format('DD.MM.YYYY')}`
      }
      return 'Yok'
    },
  },
}
</script>
