<template>
  <b-card
    v-if="dataList.length > 0"
    title="Yetkili Kişiler"
  >
    <b-list-group>
      <b-list-group-item
        v-for="person in dataList"
        :key="person.id"
      >
        <div
          class="text-primary font-weight-bold"
          :class="person.deleted? 'text-muted' : ''"
        >
          {{ person.related_person_type }}
        </div>
        <div
          class="font-medium-2 font-weight-bold"
          :class="person.deleted? 'text-muted' : ''"
        >
          {{ person.name }}
        </div>
        <div
          v-if="person.phone"
          class="mt-1"
          :class="person.deleted? 'text-muted' : ''"
        >
          <FeatherIcon icon="PhoneIcon" /> {{ person.phone }}
        </div>
        <div
          class="mt-1"
          :class="person.deleted? 'text-muted' : ''"
        >
          <FeatherIcon icon="MailIcon" /> {{ person.email }}
        </div>
        <div
          v-if="person.deleted"
          class="mt-1 text-danger font-small-2"
        >
          {{ moment(person.deleted).format('LL') }} tarihinde ilgili kişi silindi.
        </div>
      </b-list-group-item>
    </b-list-group>
    <div class="text-center m-1">
      <small class="text-primary">Yetkili kişi ekleme işleminizi <b>"Bilgileri Güncelle"</b> butonuna tıklayarak yapabilirsiniz.</small>
    </div>
  </b-card>
</template>

<script>
import { BCard, BListGroup, BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'RelatedPersonView',
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
  },
  props: {
    customerId: {
      type: [String, Number],
      default: null,
    },
  },
  computed: {
    dataList() {
      return this.$store.getters['customerRelatedPerson/dataList']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      let id = null
      if (this.customerId) {
        id = this.customerId
      } else if (this.$route.params.id) {
        id = this.$route.params.id
      }
      if (id) {
        this.$store.dispatch('customerRelatedPerson/getDataList', {
          where: {
            'com_customer_related_person.id_com_customer': id,
          },
        })
      }
    },
  },
}
</script>
