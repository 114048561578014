<template>
  <b-card no-body>
    <b-card-body>
      <b-row class="d-flex align-items-center">
        <b-col>
          <b-form-group
            label="İş Emri Arama"
            label-for="keyword"
          >
            <b-form-input
              id="keyword"
              v-model="keyword"
              placeholder="İş Emri No veya Araç Plaka ile arama yapabilirsiniz..."
              @keydown.enter="getDataList"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
          md="auto"
        >
          <b-button
            variant="success"
            @click="getDataList"
          >
            <FeatherIcon icon="SearchIcon" />
            İŞ EMRİ BUL
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <loading v-if="dataLoading" />
    <b-table
      v-else-if="dataList.length > 0"
      :items="dataList"
      :fields="fields"
      striped
    >
      <template #cell(Is_Emri_No)="data">
        <div class="text-primary font-weight-bold">
          {{ data.item.Is_Emri_No }}
        </div>
      </template>
      <template #cell(Marka)="data">
        <div class="font-weight-bold">
          {{ data.item.Marka }}
        </div>
        <div class="font-small-3 text-warning">
          {{ data.item.Arac_Model }} {{ data.item.Model_Yili }}
        </div>
        <div class="font-weight-bolder text-danger">
          {{ data.item.Plaka }}
        </div>
        <div class="font-weight-bold font-small-2 text-body">
          {{ data.item.Sase_No }} - {{ data.item.Motor_No }}
        </div>
      </template>
      <template #cell(control)="data">
        <b-button
          variant="danger"
          pill
          :to="'/service/work-orders/add/' + data.item.id"
        >
          İŞ EMRİNİ SEÇ <FeatherIcon icon="ChevronRightIcon" />
        </b-button>
      </template>
    </b-table>
    <b-card-body
      v-else
      class="mt-0 pt-0"
    >
      <b-alert
        variant="danger"
        show
      >
        <div class="alert-body text-center p-2">
          <div class="font-weight-bolder font-medium-2 mb-1">
            Aradığınız iş emri bulunamadı.
          </div>
          <b-button
            variant="danger"
            to="/service/work-orders/add"
            pill
          >
            İş Emri Oluştur
          </b-button>
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BFormGroup, BFormInput, BButton, BRow, BCol, BTable, BAlert,
} from 'bootstrap-vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'SearchWorkOrders',
  components: {
    BCard,
    BCardBody,
    BFormGroup,
    BFormInput,
    BButton,
    BRow,
    BCol,
    BTable,
    BAlert,
    Loading,
  },
  data() {
    return {
      keyword: null,
      fields: [
        {
          key: 'Is_Emri_No',
          label: 'İŞ EMRİ NO',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'Marka',
          label: 'ARAÇ',
        },
        {
          key: 'Lokasyon',
          label: 'LOKASYON',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
      ],
      dataQuery: {
        where: {
          'tbl_IsEmirleri.Belge_Tipi': 'Açik Is Emri',
          'DATE(tbl_IsEmirleri.DB_Yazdirma_Tarihi) >=': '2025-01-01',
        },
        limit: 50,
        start: 0,
        onlyOpen: true,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['serviceRPA/dataList']
    },
    dataLoading() {
      return this.$store.getters['serviceRPA/dataLoading']
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      if (this.keyword) {
        this.dataQuery.or_like = {
          'tbl_IsEmirleri.Plaka': this.keyword,
          'tbl_IsEmirleri.Is_Emri_No': this.keyword,
        }
      } else {
        delete this.dataQuery.or_like
      }
      this.$store.dispatch('serviceRPA/getDataList', this.dataQuery)
    },
  },
}
</script>
