<template>
  <div>
    <b-card>
      <surveys-report-search-component :filter-action="getDataList" />
    </b-card>
    <template v-if="dataLoading">
      <loading />
    </template>
    <template v-else>
      <b-card no-body>
        <b-card-body><surveys-report-istatistics-card :statistics-data="istatisticsData" /></b-card-body>
        <b-table
          v-if="reportData.length > 0"
          responsive
          :items="reportData"
          :fields="fields"
          striped
          small
          class="font-small-3 text-nowrap text-center"
        >
          <template #cell(interview_number)="data">
            <template v-if="data.item.interview_number">
              <a
                :href="'/interviews/view/'+data.item.interview_number"
                target="_blank"
                class="font-weight-bolder"
              >{{ data.item.interview_number }}</a>
              <div>
                <small class="text-muted">Ayrıntılar için tıklayın.</small>
              </div>
            </template>
            <template v-else>
              <small class="text-muted">-</small>
            </template>
          </template>
          <template #cell(username)="data">
            <template v-if="data.item.username">
              {{ data.item.username }}
            </template>
            <template v-else>
              <small class="text-muted">Otomatik oluşturuldu</small>
            </template>
          </template>
          <template #cell(created)="data">
            {{ moment(data.item.created).format('DD.MM.YYYY HH:mm:ss') }}
          </template>
          <template #cell(close_date)="data">
            <div v-if="data.item.close_date">
              {{ moment(data.item.close_date).format('DD.MM.YYYY HH:mm:ss') }}
            </div>
            <div v-else>
              <small class="text-muted">Anket henüz cevaplanmadı</small>
            </div>
          </template>
        </b-table>
        <b-card-footer>
          <b-row>
            <b-col>
              <b-pagination
                v-model="currentPage"
                :total-rows="dataCounts"
                :per-page="perPage"
                first-number
                last-number
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </template>
              </b-pagination>
            </b-col>
            <b-col>
              <div class="text-right">
                <p>Toplam <b>{{ dataCounts }}</b> adet sonuç bulundu.</p>
              </div>
            </b-col>
          </b-row>
        </b-card-footer>
      </b-card>
    </template>

  </div>
</template>
<script>

import {
  BCard, BCardFooter, BCol, BPagination, BRow, BTable, BCardBody,
} from 'bootstrap-vue'
import SurveysReportSearchComponent from '@/views/Reports/Crm/SurveysReport/Components/FilterComponent.vue'
import Loading from '@/layouts/components/Loading.vue'
import SurveysReportIstatisticsCard from '@/views/Reports/Crm/SurveysReport/Elements/Statistics.vue'

export default {
  name: 'SurveyReportIndex',
  components: {
    SurveysReportIstatisticsCard,
    BPagination,
    BRow,
    BCardFooter,
    BCol,
    BTable,
    Loading,
    SurveysReportSearchComponent,
    BCard,
    BCardBody,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      dataQuery: {
        limit: 25,
        start: 0,
      },
      fields: [
        {
          key: 'survey_code',
          label: 'Anket Kodu',
        },
        {
          key: 'interview_number',
          label: 'Görüşme Notu',
        },
        {
          key: 'customer',
          label: 'Müşteri/Firma',
        },
        {
          key: 'survey_type',
          label: 'Anket Tipi',
        },
        {
          key: 'survey_status',
          label: 'Anket Durumu',
        },
        {
          key: 'survey_point',
          label: 'Anket Puanı',
        },
        {
          key: 'username',
          label: 'Anketi Oluşturan',
        },
        {
          key: 'survey_user',
          label: 'Anket Danışmanı',
        },
        {
          key: 'created',
          label: 'Anket Tarihi',
        },
        {
          key: 'close_date',
          label: 'Cevaplanma Tarihi',
        },
      ],
    }
  },
  computed: {
    filterData() {
      return this.$store.getters['surveysReport/getFilterData']
    },
    reportData() {
      return this.$store.getters['surveysReport/getDataList']
    },
    dataCounts() {
      return this.$store.getters['surveysReport/getDataCount']
    },
    dataLoading() {
      return this.$store.getters['surveysReport/getDataLoading']
    },
    istatisticsData() {
      return this.$store.getters['surveysReport/getIstatistics']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 25
      this.getDataList()
    },
  },
  methods: {
    getDataList() {
      const dataQuery = {}
      if (this.filterData.sdate) {
        dataQuery.sdate = this.filterData.sdate
      }
      if (this.filterData.edate) {
        dataQuery.edate = this.filterData.edate
      }
      if (this.filterData.id_com_survey_type) {
        dataQuery.id_com_survey_type = this.filterData.id_com_survey_type
      }
      if (this.filterData.id_com_survey_status) {
        dataQuery.id_com_survey_status = this.filterData.id_com_survey_status
      }
      dataQuery.start = this.dataQuery.start
      dataQuery.limit = this.dataQuery.limit
      this.$store.dispatch('surveysReport/dataList', dataQuery)
    },
  },
}
</script>
