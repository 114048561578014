import moment from 'moment/moment'
import axiosIns from '@/libs/axios'

const ROOT_URL = '/yeastar/cdr'
export default {
  namespaced: true,
  state: {
    dataList: [],
    dataCounts: 0,
    dataItem: {
      id: null,
      datetime: null,
      timestamp: null,
      uid: null,
      clid: null,
      src: null,
      srcname: null,
      srcaddr: null,
      dst: null,
      phone: null,
      dstname: null,
      dcontext: null,
      channel: null,
      dstchannel: null,
      srctrunk: null,
      dsttrunk: null,
      lastapp: null,
      lastdata: null,
      duration: null,
      ringduration: null,
      talkduration: null,
      disposition: null,
      amaflags: null,
      calltype: null,
      accountcode: null,
      uniqueid: null,
      didnumber: null,
      dodnumber: null,
      recordfile: null,
      recordpath: null,
      srcchanurl: null,
      dstchanurl: null,
      reasonpartya: null,
      reasonpartyb: null,
      reasonpartyc: null,
      reasonpartyd: null,
      reasonpartye: null,
      reasonpartyf: null,
      displayonweb: null,
      src_del_cdr: null,
      dst_del_cdr: null,
      src_del_recording: null,
      dst_del_recording: null,
      srcnameprefix: null,
      dstnameprefix: null,
      misscall_isread: null,
      in2outbound: null,
      concurrentcalls: null,
      videocall: null,
      rascall: null,
      tryvideocall: null,
      amount: null,
      rate_id: null,
      displayonext: null,
      dstexten: null,
      callflow: null,
      callflownumber: null,
      holdduration: null,
      press_timeout: null,
      press_invalid: null,
      press: null,
      groupid: null,
      reasonpartyg: null,
      reasonpartyh: null,
      reasonpartyi: null,
      process_result: null,
      process_ext: null,
      process_time: null,
      ivrdst: null,
      linkedid: null,
      attended_requestor: null,
      is_auto_fill_0: null,
      contactname: null,
      contacttype: null,
      number_encryption_object: null,
    },
    dataSave: {
      message: null,
      status: null,
      id: null,
    },
    interview: {
      initial: '1',
      status: '1',
      content: 'Ip Santral Otomatik Görüşme Oluşturma',
      id_com_interview_type: null,
      id_com_interview_subject: null,
      id_com_meet: null,
      id_com_customer: null,
      id_com_brand: '6',
      idate: moment().format('YYYY-MM-DD'),
      itime: moment().format('HH:MM'),
    },
    viewModal: {
      id: null,
      status: false,
      customerOnly: false,
    },
    filterData: {
      keywords: null,
      sdate: moment().subtract(2, 'days').format('YYYY-MM-DD'), // 2 gün geri alınmış tarih
      edate: moment().format('YYYY-MM-DD'),
      disposition: null,
    },
    dataLoading: false,
  },
  getters: {
    dataList(state) {
      return state.dataList
    },
    dataCounts(state) {
      return state.dataCounts
    },
    dataItem(state) {
      return state.dataItem
    },
    dataSaveStatus(state) {
      return state.dataSave
    },
    getInterviewData(state) {
      return state.interview
    },
    getViewModal(state) {
      return state.viewModal
    },
    dataLoading(state) {
      return state.dataLoading
    },
    getFilterData(state) {
      return state.filterData
    },
  },
  mutations: {
    SET_DATA_LIST(state, data) {
      state.dataList = data
    },
    SET_DATA_ITEM(state, data) {
      state.dataItem = data
    },
    SET_DATA_COUNTS(state, data) {
      state.dataCounts = data
    },
    SET_DATA_SAVE(state, data) {
      state.dataSave = data
    },
    RESET_DATA_ITEM(state) {
      state.dataItem = {
        id: null,
        datetime: null,
        timestamp: null,
        uid: null,
        clid: null,
        src: null,
        srcname: null,
        srcaddr: null,
        dst: null,
        phone: null,
        dstname: null,
        dcontext: null,
        channel: null,
        dstchannel: null,
        srctrunk: null,
        dsttrunk: null,
        lastapp: null,
        lastdata: null,
        duration: null,
        ringduration: null,
        talkduration: null,
        disposition: null,
        amaflags: null,
        calltype: null,
        accountcode: null,
        uniqueid: null,
        didnumber: null,
        dodnumber: null,
        recordfile: null,
        recordpath: null,
        srcchanurl: null,
        dstchanurl: null,
        reasonpartya: null,
        reasonpartyb: null,
        reasonpartyc: null,
        reasonpartyd: null,
        reasonpartye: null,
        reasonpartyf: null,
        displayonweb: null,
        src_del_cdr: null,
        dst_del_cdr: null,
        src_del_recording: null,
        dst_del_recording: null,
        srcnameprefix: null,
        dstnameprefix: null,
        misscall_isread: null,
        in2outbound: null,
        concurrentcalls: null,
        videocall: null,
        rascall: null,
        tryvideocall: null,
        amount: null,
        rate_id: null,
        displayonext: null,
        dstexten: null,
        callflow: null,
        callflownumber: null,
        holdduration: null,
        press_timeout: null,
        press_invalid: null,
        press: null,
        groupid: null,
        reasonpartyg: null,
        reasonpartyh: null,
        reasonpartyi: null,
        process_result: null,
        process_ext: null,
        process_time: null,
        ivrdst: null,
        linkedid: null,
        attended_requestor: null,
        is_auto_fill_0: null,
        contactname: null,
        contacttype: null,
        number_encryption_object: null,
      }
    },
    SET_DATA_LOADING(state, data) {
      state.dataLoading = data
    },
    RESET_VIEW_MODAL(state) {
      state.viewModal = {
        id: null,
        status: false,
        customerOnly: false,
      }
    },
    RESET_INTERVIEW_DATA(state) {
      state.interview = {
        initial: '1',
        status: '1',
        content: 'Ip Santral Entegrasyon Otomatik Görüşme Oluşturma',
        content_line: null,
        id_com_interview_type: '8',
        id_com_interview_subject: null,
        id_com_interview_status: null,
        id_com_meet: null,
        id_com_customer: null,
        id_com_brand: '10',
        idate: moment().format('YYYY-MM-DD'),
        itime: moment().format('HH:MM'),
      }
    },
    RESET_FILTER_DATA(state) {
      state.filterData = {
        keywords: null,
        sdate: moment().subtract(2, 'days').format('YYYY-MM-DD'), // 2 gün geri alınmış tarih
        edate: moment().format('YYYY-MM-DD'),
        disposition: null,
      }
    },
  },
  actions: {
    getDataList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    getCallList({ commit }, params) {
      commit('SET_DATA_LOADING', true)
      const data = JSON.stringify(params)
      axiosIns
        .post(`${ROOT_URL}/callList`, data)
        .then(response => {
          commit('SET_DATA_LIST', response.data.data)
          commit('SET_DATA_COUNTS', response.data.count)
          commit('SET_DATA_LOADING', false)
        })
        .catch(error => console.error(error))
    },
    getDataItem({ commit }, id) {
      const data = JSON.stringify({
        where: {
          'com_yeastar_calls.id': id,
        },
        array: false,
      })
      return axiosIns
        .post(ROOT_URL, data)
        .then(response => {
          commit('SET_DATA_ITEM', response.data.data)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    saveData({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/saveData`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    saveInterview({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post(`${ROOT_URL}/saveInterview`, data)
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    removeData({ commit }, id) {
      axiosIns
        .post(`${ROOT_URL}/deleteData`, { id })
        .then(response => {
          commit('SET_DATA_SAVE', response.data)
        })
        .catch(error => console.error(error))
    },
    resetFilterData({ commit }) {
      commit('RESET_FILTER_DATA')
    },
  },
}
