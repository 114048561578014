<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Kasko Kontrol Listesi</b-card-title>
      <div>
        <b-button
          :href="downloadExcelTrafik"
          target="_blank"
          variant="warning"
          class="mr-1"
        >
          <FeatherIcon icon="DownloadIcon" />
          Trafik Excel
        </b-button>
        <b-button
          :href="downloadExcelKasko"
          target="_blank"
          variant="success"
        >
          <FeatherIcon icon="DownloadIcon" />
          Kasko Excel
        </b-button>
      </div>
    </b-card-header>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(title)="data">
        {{ data.item.title }}
        <div class="text-primary font-small-2">
          {{ data.item.chasis }}
        </div>
        <div class="text-warning font-small-2">
          {{ data.item.licence_plate }}
        </div>
      </template>
      <template #cell(edate)="data">
        {{ moment(data.item.edate).format('DD.MM.YYYY') }}
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            variant="success"
            size="sm"
            class="mr-1"
            @click="submitForm(data.item.id, data.item.id_com_rental_stocks,'1')"
          >
            Yenile
          </b-button>
          <b-button
            variant="danger"
            size="sm"
            @click="submitForm(data.item.id,data.item.id_com_rental_stocks,'0')"
          >
            Yenileme
          </b-button>
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <div class="d-flex align-items-center justify-content-between">
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCounts"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
        <div class="text-muted text-right">
          Toplam {{ dataCounts | toNumber }} adet kayıt bulundu.
        </div>
      </div>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BTable, BPagination, BCardFooter, BButton, BCardHeader, BCardTitle,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BTable,
    BPagination,
    BCardFooter,
    BButton,
    BCardHeader,
    BCardTitle,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'arackodu',
          label: 'ARAÇ KODU',
          thClass: 'width-150',
        },
        {
          key: 'title',
          label: 'ARAÇ BİLGİSİ',
        },
        {
          key: 'edate',
          label: 'BİTİŞ TARİHİ',
        },
        {
          key: 'control',
          label: '',
          tdClass: 'text-nowrap',
        },
      ],
      dataQuery: {
        limit: 20,
        start: 0,
      },
    }
  },
  computed: {
    downloadExcelTrafik() {
      return `${this.$store.state.app.baseURL}/exports/rental/trafik?token=${localStorage.getItem('downloadToken')}`
    },
    downloadExcelKasko() {
      return `${this.$store.state.app.baseURL}/exports/rental/kasko?token=${localStorage.getItem('downloadToken')}`
    },
    searchData() {
      return this.$store.getters['rentalKasko/searchData']
    },
    dataList() {
      return this.$store.getters['rentalKasko/dataList']
    },
    dataCounts() {
      return this.$store.getters['rentalKasko/dataCounts']
    },
    saveData() {
      return this.$store.getters['rentalKasko/dataSaveStatus']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
  },
  methods: {
    keywordSearch() {
      this.dataQuery.or_like = {
        'com_rental_stocks.arackodu': this.searchData.keyword,
        'com_rental_stocks.licence_plate': this.searchData.keyword,
        'com_rental_stocks.chasis': this.searchData.keyword,
      }
      this.getDataList()
    },
    getDataList() {
      this.$store.dispatch('rentalKasko/getDataList', this.dataQuery)
    },
    submitForm(id, stockID, status) {
      this.$store.dispatch('rentalKasko/saveData', {
        id,
        kasko_renew: status,
        id_com_rental_stocks: stockID,
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
