<template>
  <b-row>
    <b-col
      cols="12"
      md="4"
    >
      <b-card title="İş Emri">
        <b-list-group>
          <list-item
            v-if="dataItem.work_no"
            item-title="İş Emri No"
            :item-value="dataItem.work_no"
          />
          <list-item
            v-if="dataItem.wdate"
            item-title="İş Emri Tarihi"
            :item-value="moment(dataItem.wdate).format('DD.MM.YYYY')"
          />
          <list-item
            v-if="dataItem.service_section"
            item-title="Bölüm"
            :item-value="dataItem.service_section"
          />
          <list-item
            v-if="dataItem.service_work"
            item-title="Yapılacak İşlem"
            :item-value="dataItem.service_work"
          />
          <list-item
            v-if="dataItem.is_da"
            item-title="İşlem Tipi"
            item-value="S&T"
          />
          <list-item
            v-if="dataItem.is_repair"
            item-title="İşlem Tipi"
            item-value="Onarım"
          />
          <list-item
            v-if="dataItem.is_change"
            item-title="İşlem Tipi"
            item-value="Değişim"
          />
          <list-item
            v-if="dataItem.is_work"
            item-title="İşlem Tipi"
            item-value="Diğer"
          />
          <list-item
            v-if="dataItem.target_min"
            item-title="Standart Süre"
            :item-value="dataItem.target_min + ' dk'"
          />
          <list-item
            item-title="Çalışılan Süre"
            :item-value="dataItem.duration? moment.utc(moment.duration(dataItem.duration * 1000).asMilliseconds()).format('HH:mm:ss') : '-'"
          />
          <list-item
            item-title="İş Emri Durumu"
            :item-value="dataItem.workStatus? 'İşlem Tamamlandı' : 'İşlem Devam Ediyor'"
          />
          <list-item
            v-if="dataItem.service_worker"
            item-title="Teknisyen"
            :item-value="dataItem.service_worker"
          />
        </b-list-group>
      </b-card>
      <work-order-card v-if="rpaDataItem.id" />
    </b-col>
    <b-col
      cols="12"
      md="8"
    >
      <b-card title="Teknisyen İşlem Geçmişi">
        <work-lines
          v-if="dataItem"
          :data-item="dataItem"
        />
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BListGroup,
} from 'bootstrap-vue'
import ListItem from '@/views/Service_work_orders/Orders/View/ListItem.vue'
import WorkLines from '@/views/Service_work_orders/Orders/WorkerView/WorkLines.vue'
import WorkOrderCard from '@/views/Service_work_orders/Orders/WorkerView/WorkOrderCard.vue'

export default {
  name: 'View',
  components: {
    BCard,
    BRow,
    BCol,
    BListGroup,
    ListItem,
    WorkLines,
    WorkOrderCard,
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceWorkOrders/dataItem']
    },
    rpaDataItem() {
      return this.$store.getters['serviceRPA/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('serviceWorkOrders/getDataItem', this.$route.params.id)
        .then(response => {
          if (response.id_tbl_IsEmirleri) {
            this.$store.dispatch('serviceRPA/getDataItem', response.id_tbl_IsEmirleri)
          }
        })
    },
  },
}
</script>
