<template>
  <div>
    <template v-if="loadingData">
      <loading />
    </template>
    <b-row v-else>
      <b-col
        md="4"
        class="border-right"
      >
        <yeastar-call-info-card />
      </b-col>
      <b-col>
        <yeastar-interview-card
          v-if="modalData.customerOnly"
          :call-type="callType"
        />
        <yeastar-customer-card
          v-else
          :refresh-data="getData"
          :phone="dataItem.phone"
        />
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol } from 'bootstrap-vue'
import Loading from '@/layouts/components/Loading.vue'
import YeastarCallInfoCard from '@/views/Crm/YeastarIpCentral/Components/CallInfoCard.vue'
import YeastarInterviewCard from '@/views/Crm/YeastarIpCentral/Components/InterviewCard.vue'
import YeastarCustomerCard from '@/views/Crm/YeastarIpCentral/Components/CustomerCard.vue'

export default {
  name: 'YeastarViewModal',
  components: {
    YeastarCustomerCard,
    YeastarInterviewCard,
    YeastarCallInfoCard,
    Loading,
    BRow,
    BCol,
  },
  props: {
    callType: {
      type: String,
      required: true,
    },
    yeastarId: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      loadingData: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['yeastar/dataItem']
    },
    customerData() {
      return this.$store.getters['customers/getCustomers']
    },
    modalData() {
      return this.$store.getters['yeastar/getViewModal']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.loadingData = true
      this.$store.dispatch('yeastar/getDataItem', this.yeastarId)
        .then(response => {
          if (response) {
            this.$store.commit('customers/RESET_CUSTOMER_FORM', true)
            this.$store.dispatch('customers/getData', {
              where: {
                'com_customer.phone': this.callType === 'Inbound' ? response.src : this.callType === 'Outbound' ? response.dst : this.callType === 'Callback' ? response.dst : response.src,
              },
              array: false,
            }).then(responseC => {
              if (responseC) {
                this.modalData.customerOnly = true
              } else {
                this.modalData.customerOnly = false
              }
              // istek cevabı geldiğinde loading direkt kalkabilir. Müşteri kartı yok ise ekranda müşteri oluşturma kartını göstermemiz gerekli.
              this.loadingData = false
            })
          }
        })
    },
  },
}
</script>
