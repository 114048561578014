<template>
  <b-card no-body>
    <b-card-body>
      <b-row>
        <b-col>
          <filter-card :get-data-list="getDataList" />
        </b-col>
        <b-col
          cols="12"
          md="auto"
          class="text-right mt-2"
        >
          <b-button
            variant="danger"
            :to="$route.path + '/search-work-orders'"
          >
            <FeatherIcon icon="PlusIcon" /> Oluştur
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(wdate)="data">
        {{ moment(data.item.wdate).format('DD.MM.YYYY') }}
      </template>
      <template #cell(service_section)="data">
        {{ data.item.service_section }}
        <div class="font-small-2 text-warning">
          {{ data.item.service_worker }}
        </div>
      </template>
      <template #cell(location)="data">
        {{ data.item.location }}
      </template>
      <template #cell(duration)="data">
        <div class="text-center">
          <div
            class="font-medium-2 font-weight-bolder"
            :class="!data.item.duration? 'text-danger' : ''"
          >
            {{ data.item.duration? moment.utc(moment.duration(data.item.duration * 1000).asMilliseconds()).format('HH:mm:ss') : 'Beklemede' }}
          </div>
          <b-badge
            v-if="data.item.duration"
            :variant="data.item.workStatus? 'success' : 'danger'"
          >
            {{ data.item.workStatus? 'İşlem Tamamlandı' : 'İşlem Devam Ediyor' }}
          </b-badge>
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <list-buttons
            primary-text="Görüntüle"
            :primary-action="$route.path + '/view/' + data.item.id"
            :edit-action="$route.path + '/edit/' + data.item.id"
            :delete-action="removeData"
            :data-id="data.item.id"
          />
        </div>
      </template>
    </b-table>
    <b-card-footer>
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </b-card>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BBadge, BRow, BCol,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ListButtons from '@/layouts/components/common/ListDropdownButtons.vue'
import FilterCard from '@/views/Service_work_orders/Orders/elements/FilterCard.vue'

const tableName = 'com_service_work_orders'
export default {
  name: 'Index',
  components: {
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BBadge,
    BRow,
    BCol,
    ListButtons,
    FilterCard,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 20,
      fields: [
        {
          key: 'wdate',
          label: 'TARİH',
        },
        {
          key: 'work_no',
          label: 'İŞ EMRİ NO',
        },
        {
          key: 'service_section',
          label: 'BÖLÜM',
        },
        {
          key: 'location',
          label: 'LOKASYON',
        },
        {
          key: 'duration',
          label: 'ÇALIŞILAN SÜRE',
          thClass: 'text-center',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '140px' },
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.wdate AS wdate`,
          `${tableName}.work_no AS work_no`,
          `${tableName}.is_da AS is_da`,
          `${tableName}.is_repair AS is_repair`,
          `${tableName}.is_change AS is_change`,
          `${tableName}.is_work AS is_work`,
          'com_location.title AS location',
          'com_service_sections.title AS service_section',
          'com_service_workers.fullname AS service_worker',
          'com_service_works.title AS service_work',
        ],
        limit: 20,
        start: 0,
        duration: true,
        order_by: ['com_service_work_orders.id', 'DESC'],
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['serviceWorkOrders/dataList']
    },
    dataCounts() {
      return this.$store.getters['serviceWorkOrders/dataCounts']
    },
    saveData() {
      return this.$store.getters['serviceWorkOrders/dataSaveStatus']
    },
    filterParams() {
      return this.$store.getters['serviceWorkOrders/filterParams']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 20
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.getDataList()
    this.getLocations()
  },
  methods: {
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        where: {
          'com_location.id !=': 4,
        },
      })
    },
    getDataList() {
      if (this.filterParams.work_no) {
        this.dataQuery.like = {
          'com_service_work_orders.work_no': this.filterParams.work_no,
        }
      } else {
        delete this.dataQuery.like
      }
      if (this.filterParams.id_com_location) {
        this.dataQuery.where = {
          'com_service_work_orders.id_com_location': this.filterParams.id_com_location,
        }
      } else {
        delete this.dataQuery.where
      }
      this.$store.dispatch('serviceWorkOrders/getDataList', this.dataQuery)
    },
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('serviceWorkOrders/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
