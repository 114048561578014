<template>
  <b-card title="Kişi Bilgileri">
    <b-list-group>
      <list-item
        title="Müşteri Tipi"
        :value="customer.customer_type"
      />
      <list-item
        title="Adı Soyadı"
        :value="customer.name"
      />
      <list-item
        title="Firma Adı"
        :value="customer.company_name"
      />
      <list-item
        title="Telefon"
        :value="customer.phone"
      />
      <list-item
        title="Telefon 2"
        :value="customer.phone2"
      />
      <list-item
        title="E-Posta"
        :value="customer.email"
      />
      <list-item
        title="Müşteri Notu"
        :value="customer.notes"
      />
    </b-list-group>
  </b-card>
</template>

<script>
import {
  BCard,
  BListGroup,
} from 'bootstrap-vue'
import ListItem from '@/views/Customers/view/ListItem.vue'

export default {
  name: 'Personal',
  components: {
    BCard,
    BListGroup,
    ListItem,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
}
</script>

<style scoped>

</style>
