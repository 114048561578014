<template>
  <div>
    <b-list-group>
      <list-item
        title="Firma / Müşteri"
        :value="customerData.company_name ? customerData.company_name : customerData.name ? customerData.name : 'Müşteri kartı bulunmuyor.'"
      />
      <list-item
        title="Telefon"
        :value="dataItem.phone"
      />
      <list-item
        title="Tarih Saat"
        :value="moment(dataItem.datetime).format('DD.MM.YYYY')"
      />
      <list-item
        title="Süre"
        :value="dataItem.duration+' Sn.'"
      />
      <list-item
        title="Görüşme Durumu"
        :value="dataItem.disposition"
      />
      <list-item
        title="Görüşme Tipi"
        :value="dataItem.calltype == 'Inbound' ? 'Gelen Arama' : dataItem.calltype == 'Outbound' ? 'Giden Arama' : dataItem.disposition == 'Callback' ? 'Geri Arama' : '-'"
      />
    </b-list-group>
  </div>
</template>
<script>

import { BListGroup } from 'bootstrap-vue'
import ListItem from '@/views/Crm/View/ListItem.vue'

export default {
  name: 'YeastarCallInfoCard',
  components: { ListItem, BListGroup },
  computed: {
    dataItem() {
      return this.$store.getters['yeastar/dataItem']
    },
    customerData() {
      return this.$store.getters['customers/getCustomer']
    },
  },
}
</script>
