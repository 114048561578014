<template>
  <div>
    <b-form-group
      label="İş Emri No"
      label-for="work_no"
    >
      <validation-provider
        #default="{ errors }"
        name="İş Emri No"
        rules="required"
      >
        <b-form-input
          id="work_no"
          v-model="dataItem.work_no"
          placeholder="İş Emri No"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'WorkNo',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  props: {
    rpaDataItem: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['serviceWorkOrders/dataItem']
    },
  },
  created() {
    localize('tr')
  },
  mounted() {
    this.setWorkNo()
  },
  methods: {
    setWorkNo() {
      if (this.rpaDataItem) {
        if (this.rpaDataItem.Is_Emri_No) {
          this.dataItem.work_no = this.rpaDataItem.Is_Emri_No
        }
      }
    },
  },
}
</script>
