<template>
  <div>
    <interview-transfer-search />
    <duplicated-result-list
      v-if="dataList.interviews.data.length > 0"
      :table-name="dataList.interviews.tableName"
      :fields="dataList.interviews.fields"
      :data-item="dataList.interviews.data"
    />
    <b-card
      v-if="dataList.interviews.data.length > 0"
      title="Görüşmeleri Aktar"
    >
      <b-row class="d-flex align-items-center">
        <b-col>
          <b-form-group
            label="Aktarılacak Kullanıcı"
            label-for="id_com_user"
          >
            <v-select
              id="id_com_user"
              v-model="interview.id_com_user"
              label="title"
              :reduce="user => user.id"
              :options="users"
              placeholder="Aktarılacak Kullanıcı"
            >
              <template v-slot:option="option">
                {{ option.title }}
                <div v-if="option.user_type">
                  <small class="text-warning">{{ option.user_type }}</small>
                  <small class="text-muted"> / {{ option.department }}</small>
                  <small class="text-muted"> / {{ option.brand }}</small>
                  <small class="text-muted"> / {{ option.status ? 'Aktif' : 'Pasif' }}</small>
                </div>
              </template>
              <template v-slot:selected-option="option">
                {{ option.title }} <small class="text-warning ml-1"> {{ option.user_type }}</small>
              </template>
            </v-select>
          </b-form-group>
          <b-form-group
            label="Aktarma Notu"
            label-for="content"
          >
            <b-form-input
              v-model="interview.content"
              placeholder="Not Giriniz"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="text-center"
        >
          <b-button
            variant="primary"
            :disabled="!interview.id_com_user || !interview.content || transferProcess"
            @click="transferInterviews"
          >
            {{ interviewTransferBtnTitle }}
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <b-modal
      v-model="transferModal.status"
      centered
      no-close-on-backdrop
      no-close-on-esc
      hide-header-close
      no-enforce-focus
      hide-footer
      title="Aktarılıyor"
    >
      <div>
        <label>{{ transferModal.status_label }}</label>
        <b-progress
          :value="transferModal.value"
          :max="transferModal.total"
        />
      </div>
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BCol, BRow, BButton, BFormGroup, BFormInput, BModal, BProgress,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import DuplicatedResultList from '@/views/ManagerTools/Components/ResultList.vue'
import InterviewTransferSearch from '@/views/ManagerTools/Components/InterviewTransfer/InterviewTransferSearchCard.vue'

export default {
  name: 'InterviewTransferCard',
  components: {
    InterviewTransferSearch,
    vSelect,
    BFormGroup,
    DuplicatedResultList,
    BCol,
    BCard,
    BRow,
    BButton,
    BFormInput,
    BModal,
    BProgress,
  },
  data() {
    return {
      transferProcess: false,
      interviewTransferBtnTitle: 'Görüşmeleri Aktar',
      interview: {
        id_com_user: null,
        content: 'Destek Talebi #[...] ILE MEDUSA OTOMATİK GÖRÜŞME AKTARMA',
      },
      transferModal: {
        status: false,
        status_label: 'Hesaplanıyor..',
        total: 0,
        value: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['interviewTransfer/dataItem']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    this.$store.commit('interviewTransfer/RESET_INTERVIEW_LIST')
    this.$store.commit('interviewCloses/RESET_INTERVIEW_LIST')
  },
  methods: {
    transferInterviews() {
      this.transferProcess = true
      this.$swal({
        title: 'Görüşme aktarma',
        text: 'Seçilen görüşmeler belirtilen durum ve not ile hatırlatmalar dahil aktarılacak. Devam etmek istiyor musun ?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.transferModal.total = 0
          this.transferModal.value = 0

          console.log('aktarım başlatılıyor.')
          const items = this.dataList.interviews.data.filter(item => item.selected === '1')
          if (items.length < 1) {
            this.$swal({
              icon: 'error',
              title: 'İşlem Başarısız',
              text: 'Aktarım için satır seçilmeli.',
              confirmButtonText: this.$store.state.app.removeResultClose,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          } else {
            this.transferModal.status = true
            this.transferModal.status_label = 'Aktarım başlatılıyor..'
          }
          console.log(`${items.length} satır aktarılacak`)
          // eslint-disable-next-line no-plusplus
          this.transferModal.total = items.length
          for (let i = 0; i < items.length; i++) {
            const item = items[i]
            this.$store.dispatch('interviewTransfer/interviewsTransfer', {
              id_com_user: this.interview.id_com_user,
              content: this.interview.content,
              interviews: [item],
            }).then(res => {
              if (!res) {
                this.transferModal.value = i + 1
                this.transferModal.status_label = `${item.interview_number} numaralı görüşme aktarılırken hata meydana geldi.`
              } else {
                this.transferModal.value = i + 1
                this.transferModal.status_label = `${item.interview_number} numaralı görüşme aktarıldı.`
              }

              if (this.transferModal.value >= items.length) {
                this.$swal({
                  icon: 'success',
                  title: this.$store.state.app.removeResultTitle,
                  text: 'Aktarım tamamlanmıştır.',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.transferModal.status = false
                this.transferProcess = false
              }
            })
          }
        }
      })
    },
  },
}
</script>
