// import axios from '@/libs/axios'
import axiosIns from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    loading: false,
    customers: [],
    customersCount: 0,
    customer: {
      id: null,
      customer_number: null,
      name: null,
      name1: null,
      surname1: null,
      company_name: null,
      company_name1: null,
      phone: null,
      phone2: null,
      email: null,
      tckn: '',
      tckn_level: '',
      tax_admin: null,
      tax_number: null,
      tax_number_level: null,
      father_name: null,
      mother_name: null,
      birthday: null,
      place_birth: null,
      address: null,
      notes: null,
      bulletin: null,
      bulletindate: null,
      kvkk: null,
      kvkkdate: null,
      referans_order_number: null,
      id_com_gender: null,
      id_com_kvkk_type: null,
      id_com_bulletin_type: null,
      id_com_city: null,
      id_com_region: null,
      id_com_district: null,
      id_com_neighborhood: null,
      id_com_job: null,
      id_com_sector: null,
      id_com_meet: null,
      id_com_customer_type: '1',
      id_com_user_fw: null,
      detailForm: false,
      logo_id: null,
      logoSendLogs: [],
      related_persons: [],
      submitStatus: false,
    },
    kvkkSms: {
      code: null,
      result: null,
      sms_status: null,
      error: null,
      countDown: 180,
    },
    relatedPersonKvkkSms: {
      code: null,
      result: null,
      sms_status: null,
      error: null,
      countDown: 180,
    },
    customerSave: {
      message: null,
      status: null,
      id: null,
    },
    phoneControl: {
      result: null,
    },
    dataHistory: {
      interview: [],
      sale_orderform: [],
      service_tracing: [],
      crm: [],
      realty_sale: [],
      rental_contracts: [],
    },
    tcControl: {
      id: null,
      status: null,
      message: null,
      level: 0,
    },
    taxNoControl: {
      id: null,
      status: null,
      message: null,
      level: 0,
    },
    filterData: {
      keyword: '',
      id_com_user: null,
      sdate: null,
      edate: null,
      detailSearch: null,
    },
  },
  getters: {
    getCustomers(state) {
      return state.customers
    },
    getCustomersCount(state) {
      return state.customersCount
    },
    getCustomer(state) {
      return state.customer
    },
    getCustomerSaveStatus(state) {
      return state.customerSave
    },
    getKvkkSms(state) {
      return state.kvkkSms
    },
    getRelatedPersonKvkkSms(state) {
      return state.relatedPersonKvkkSms
    },
    getPhoneControl(state) {
      return state.phoneControl
    },
    getHistory(state) {
      return state.dataHistory
    },
    tcControl(state) {
      return state.tcControl
    },
    taxNoControl(state) {
      return state.taxNoControl
    },
    filterData(state) {
      return state.filterData
    },
    loading(state) {
      return state.loading
    },
  },
  mutations: {
    SET_LOADING(state, data) {
      state.loading = data
    },
    CUSTOMERS_LIST(state, data) {
      state.customers = data
    },
    CUSTOMER_VIEW(state, data) {
      // eslint-disable-next-line no-param-reassign
      data.detailForm = true
      // eslint-disable-next-line no-param-reassign
      data.submitStatus = false
      state.customer = data
    },
    CUSTOMERS_COUNT(state, data) {
      state.customersCount = data
    },
    CUSTOMER_SAVE(state, data) {
      state.customerSave = data
    },
    CUSTOMER_RESET(state, data) {
      if (data.status) {
        state.customer.id = data.id
        state.kvkkSms = {
          code: null,
          result: null,
          sms_status: null,
          error: null,
          countDown: 180,
        }
      }
    },
    HISTORY_RESET(state) {
      state.dataHistory = {
        interview: [],
        sale_orderform: [],
        service_tracing: [],
        crm: [],
        realty_sale: [],
        rental_contracts: [],
      }
    },
    RESET_CUSTOMER_FORM(state) {
      state.tcControl = {
        id: null,
        status: null,
        message: null,
        level: 0,
      }
      state.taxNoControl = {
        id: null,
        status: null,
        message: null,
        level: 0,
      }
      state.customer = {
        id: null,
        customer_number: null,
        name: null,
        name1: null,
        surname1: null,
        company_name: null,
        company_name1: null,
        phone: null,
        phone2: null,
        email: null,
        tckn: null,
        tckn_level: null,
        tax_admin: null,
        tax_number: null,
        tax_number_level: null,
        father_name: null,
        mother_name: null,
        birthday: null,
        place_birth: null,
        address: null,
        notes: null,
        bulletin: null,
        bulletindate: null,
        kvkk: null,
        kvkkdate: null,
        referans_order_number: null,
        id_com_gender: null,
        id_com_kvkk_type: null,
        id_com_bulletin_type: null,
        id_com_city: null,
        id_com_region: null,
        id_com_district: null,
        id_com_neighborhood: null,
        id_com_job: null,
        id_com_sector: null,
        id_com_meet: null,
        id_com_customer_type: '1',
        id_com_user_fw: null,
        detailForm: false,
        logo_id: null,
        logoSendLogs: [],
        related_persons: [],
        submitStatus: false,
      }
    },
    KVKK_SMS_RESULT(state, data) {
      state.kvkkSms = data
    },
    RELATED_PERSON_KVKK_SMS_RESULT(state, data) {
      state.relatedPersonKvkkSms = data
    },
    KVKK_CONFIRM(state, data) {
      state.kvkkSms.result = data.result
      state.kvkkSms.error = data.error
    },
    RELATED_PERSON_KVKK_CONFIRM(state, data) {
      state.relatedPersonKvkkSms.result = data.result
      state.relatedPersonKvkkSms.error = data.error
    },
    PHONE_CONTROL(state, data) {
      state.phoneControl = data
    },
    SET_DATA_HISTORY(state, data) {
      state.dataHistory = data
    },
    SET_TC_CONTROL(state, data) {
      state.tcControl = data
    },
    SET_TAX_NO_CONTROL(state, data) {
      state.taxNoControl = data
    },
    RESET_KVKK_SMS_RESULT(state) {
      state.kvkkSms = {
        code: null,
        result: null,
        sms_status: null,
        error: null,
        countDown: 180,
      }
    },
    RESET_RELATED_PERSON_KVKK_SMS_RESULT(state) {
      state.relatedPersonKvkkSms = {
        code: null,
        result: null,
        sms_status: null,
        error: null,
        countDown: 180,
      }
    },
    RESET_PHONE_CONTROL(state) {
      state.phoneControl = {
        result: null,
      }
    },

  },
  actions: {
    customersList({ commit }, params) {
      const data = JSON.stringify(params)
      return axiosIns
        .post('/data/customers', data)
        .then(response => {
          commit('CUSTOMERS_LIST', response.data.data)
          commit('CUSTOMERS_COUNT', response.data.count)
          commit('RESET_PHONE_CONTROL')
          commit('CUSTOMER_SAVE', {
            message: null,
            status: null,
            id: null,
          })
          return response.data.count
        })
        .catch(error => console.error(error))
    },
    customerView({ commit, dispatch }, id) {
      const data = JSON.stringify({
        where: {
          'com_customer.id': id,
        },
        array: false,
      })
      return axiosIns
        .post('/data/customers', data)
        .then(response => {
          commit('CUSTOMER_VIEW', response.data.data)
          commit('CUSTOMER_SAVE', {
            message: null,
            status: null,
            id: null,
          })
          commit('HISTORY_RESET')
          if (response.data.data.id) {
            dispatch('history', response.data.data.id)
          }
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    getData({ commit, dispatch }, params) {
      commit('SET_LOADING', true)
      commit('HISTORY_RESET')
      commit('CUSTOMER_SAVE', {
        message: null,
        status: null,
        id: null,
      })
      return axiosIns
        .post('/data/customers', params)
        .then(response => {
          if (response.data.data) {
            commit('CUSTOMER_VIEW', response.data.data)
            if (response.data.data.id) {
              dispatch('history', response.data.data.id)
            }
          }
          commit('SET_LOADING', false)
          return response.data.data
        })
        .catch(error => console.error(error))
    },
    customerSave({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      return axiosIns
        .post('/data/customers/saveData', data)
        .then(response => {
          commit('CUSTOMER_SAVE', response.data)
          commit('CUSTOMER_RESET', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    kvkkSmsSend({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/customers/kvkkSms', data)
        .then(response => {
          commit('KVKK_SMS_RESULT', response.data)
        })
        .catch(error => console.error(error))
    },
    relatedPersonKvkkSmsSend({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/customers/kvkkSms', data)
        .then(response => {
          commit('RELATED_PERSON_KVKK_SMS_RESULT', response.data)
        })
        .catch(error => console.error(error))
    },
    kvkkConfirm({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/customers/kvkkConfirm', data)
        .then(response => {
          commit('KVKK_CONFIRM', response.data)
        })
        .catch(error => console.error(error))
    },
    kvkkConfirm2({ commit }, postData) {
      const data = JSON.stringify({ formData: postData })
      axiosIns
        .post('/data/customers/kvkkCustomerRelationConfirm', data)
        .then(response => {
          commit('RELATED_PERSON_KVKK_CONFIRM', response.data)
        })
        .catch(error => console.error(error))
    },
    phoneControl({ commit }, params) {
      axiosIns
        .post('/data/customers/phoneControl', params)
        .then(response => {
          commit('PHONE_CONTROL', response.data)
        })
        .catch(error => console.error(error))
    },
    tcValidate({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/data/customers/tcValidate', data)
        .then(response => {
          commit('SET_TC_CONTROL', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    taxNoValidate({ commit }, postData) {
      const data = JSON.stringify(postData)
      return axiosIns
        .post('/data/customers/taxNoValidate', data)
        .then(response => {
          commit('SET_TAX_NO_CONTROL', response.data)
          return response.data
        })
        .catch(error => console.error(error))
    },
    history({ commit }, id) {
      const data = JSON.stringify({ id_com_customer: id })
      axiosIns
        .post('/data/customers/history', data)
        .then(response => {
          commit('SET_DATA_HISTORY', response.data.data)
        })
        .catch(error => console.error(error))
    },
  },
}
