<template>
  <div>
    <b-row>
      <b-col>
        <b-form-group
          label="Hızlı Arama"
          label-for="keyword"
        >
          <b-input-group>
            <b-form-input
              id="keyword"
              v-model="quickSearch.keyword"
              placeholder="Müşteri Adı, Telefon, Başvuru No, Araç Plakası..."
              :disabled="dataLoading"
              @keydown.enter="searchAction"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                :disabled="dataLoading"
                @click="searchAction"
              ><FeatherIcon icon="SearchIcon" /></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <b-form-group
          label="Başvuru No Arama"
          label-for="id_com_crm"
        >
          <b-input-group>
            <b-form-input
              id="id_com_crm"
              v-model="quickSearch.id_com_crm"
              placeholder="Başvuru No Arama..."
              :disabled="dataLoading"
              @keydown.enter="searchAction"
            />
            <b-input-group-append>
              <b-button
                variant="primary"
                :disabled="dataLoading"
                @click="searchAction"
              ><FeatherIcon icon="SearchIcon" /></b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col cols="auto">
        <b-form-checkbox
          v-model="filterData.detailSearch"
          class="mt-2"
          name="check-button"
          style="padding-top: 5px"
          switch
          inline
          :disabled="dataLoading"
        >
          Detaylı Arama
        </b-form-checkbox>
      </b-col>
    </b-row>
    <b-row v-if="filterData.detailSearch">
      <b-col
        xs="12"
        md="3"
      >
        <b-form-group
          label="Başvuru Konusu"
          label-for="id_com_crm_subject"
        >
          <v-select
            id="id_com_crm_subject"
            v-model="filterData.id_com_crm_subject"
            placeholder="Başvuru Konusu"
            :options="crmSubjects"
            label="title"
            :close-on-select="false"
            :reduce="crmSubject => crmSubject.id"
            multiple
            :disabled="dataLoading"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="3"
      >
        <b-form-group
          label="Marka"
          label-for="id_com_brand"
        >
          <v-select
            id="id_com_brand"
            v-model="filterData.id_com_brand"
            placeholder="Marka"
            :options="brands"
            label="title"
            :close-on-select="false"
            :reduce="brand => brand.id"
            multiple
            :disabled="dataLoading"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="3"
      >
        <b-form-group
          label="Departman"
          label-for="id_com_department"
        >
          <v-select
            id="id_com_department"
            v-model="filterData.id_com_department"
            placeholder="Departman"
            :options="departments"
            label="title"
            :close-on-select="false"
            :reduce="department => department.id"
            multiple
            :disabled="dataLoading"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="3"
      >
        <b-form-group
          label="Durum"
          label-for="id_com_crm_status"
        >
          <v-select
            id="id_com_crm_status"
            v-model="filterData.id_com_crm_status"
            placeholder="Durum"
            :options="crmStatuses"
            label="title"
            :close-on-select="false"
            :reduce="crmStatus => crmStatus.id"
            multiple
            :disabled="dataLoading"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="3"
      >
        <b-form-group
          label="Açan Kullanıcı"
          label-for="id_com_open_user"
        >
          <v-select
            id="id_com_open_user"
            v-model="filterData.id_com_open_user"
            placeholder="Açan Kullanıcı"
            :options="users"
            label="title"
            :close-on-select="false"
            :reduce="user => user.id"
            multiple
            :disabled="dataLoading"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="3"
      >
        <b-form-group
          label="Atanan Yetkili"
          label-for="id_com_user"
        >
          <v-select
            id="id_com_user"
            v-model="filterData.id_com_user"
            placeholder="Atanan Yetkili"
            :options="users"
            label="title"
            :close-on-select="false"
            :reduce="user => user.id"
            multiple
            :disabled="dataLoading"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="3"
      >
        <b-form-group
          label="Başvuru Kaynağı"
          label-for="id_com_crm_applysource"
        >
          <v-select
            id="id_com_crm_applysource"
            v-model="filterData.id_com_crm_applysource"
            placeholder="Başvuru Kaynağı"
            :options="crmApplySources"
            label="title"
            :close-on-select="false"
            :reduce="user => user.id"
            multiple
            :disabled="dataLoading"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="3"
      >
        <b-form-group
          label="Başvuru Tipi"
          label-for="id_com_feedback_type"
        >
          <v-select
            id="id_com_feedback_type"
            v-model="filterData.id_com_feedback_type"
            placeholder="Başvuru Tipi"
            :options="feedbackTypes"
            label="title"
            :close-on-select="false"
            :reduce="feedbackType => feedbackType.id"
            multiple
            :disabled="dataLoading"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="6"
      >
        <b-form-group
          label="Lokasyon"
          label-for="id_com_location"
        >
          <v-select
            id="id_com_location"
            v-model="filterData.id_com_location"
            placeholder="Lokasyon"
            :options="locations"
            label="title"
            :close-on-select="false"
            :reduce="location => location.id"
            multiple
            :disabled="dataLoading"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="3"
      >
        <b-form-group
          label="Başlangıç Tarihi"
          label-for="sdate"
        >
          <b-form-datepicker
            id="sdate"
            v-model="filterData.sdate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            :disabled="dataLoading"
          />
        </b-form-group>
      </b-col>
      <b-col
        xs="12"
        md="3"
      >
        <b-form-group
          label="Bitiş Tarihi"
          label-for="edate"
        >
          <b-form-datepicker
            id="edate"
            v-model="filterData.edate"
            v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
            locale="tr"
            start-weekday="1"
            :disabled="dataLoading"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-button
          variant="primary"
          :disabled="dataLoading"
          @click="filterAction"
        >
          <FeatherIcon icon="FilterIcon" />
          Filtrele
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BFormGroup, BFormInput, BButton, BFormCheckbox, BInputGroup, BInputGroupAppend, BFormDatepicker,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'RequestFilter',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormCheckbox,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    vSelect,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    actionMethods: {
      type: Function,
    },
    // eslint-disable-next-line vue/require-default-prop
    actionFilter: {
      type: Function,
    },
  },
  data() {
    return {
      keyword: null,
      config: {
        mode: 'range',
        locale: 'tr',
        dateFormat: 'd.m.Y',
      },
    }
  },
  computed: {
    dataLoading() {
      return this.$store.getters['crmRequests/dataLoading']
    },
    quickSearch() {
      return this.$store.getters['crmRequests/getQuickSearch']
    },
    filterData() {
      return this.$store.getters['crmRequests/getFilterData']
    },
    crmSubjects() {
      return this.$store.getters['crmSubjects/getCrm_subjects']
    },
    brands() {
      return this.$store.getters['brands/getBrands']
    },
    departments() {
      return this.$store.getters['departments/getDepartments']
    },
    crmStatuses() {
      return this.$store.getters['crmStatuses/getCrm_statuses']
    },
    feedbackTypes() {
      return this.$store.getters['feedbackTypes/getFeedback_types']
    },
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    crmApplySources() {
      return this.$store.getters['crmApplySources/getCrm_applysources']
    },
  },
  created() {
    this.getCrmSubjects()
    this.getDepartments()
    this.getFeedbackTypes()
    this.getCrmLevels()
    this.getCrmStatuses()
    this.getCrmApplySources()
    this.getBrands()
    this.getLocations()
    this.getUsers()
  },
  methods: {
    getCrmSubjects() {
      this.$store.dispatch('crmSubjects/crm_subjectsList', {
        select: ['com_crm_subject.id AS id', 'com_crm_subject.title AS title'],
      })
    },
    getDepartments() {
      this.$store.dispatch('departments/departmentsList', {
        select: ['com_department.id AS id', 'com_department.title AS title'],
        where: {
          type: 1,
        },
      })
    },
    getFeedbackTypes() {
      this.$store.dispatch('feedbackTypes/feedback_typesList', {
        select: ['com_feedback_type.id AS id', 'com_feedback_type.title AS title'],
      })
    },
    getCrmLevels() {
      this.$store.dispatch('crmLevels/crm_levelsList', {
        select: ['com_crm_level.id AS id', 'com_crm_level.title AS title'],
      })
    },
    getCrmStatuses() {
      this.$store.dispatch('crmStatuses/crm_statusesList', {
        select: ['com_crm_status.id AS id', 'com_crm_status.title AS title'],
      })
    },
    getCrmApplySources() {
      this.$store.dispatch('crmApplySources/crm_applysourcesList', {
        select: ['com_crm_applysource.id AS id', 'com_crm_applysource.title AS title'],
      })
    },
    getBrands() {
      this.$store.dispatch('brands/brandsList', {
        select: ['com_brand.id AS id', 'com_brand.name AS title'],
      })
    },
    getLocations() {
      this.$store.dispatch('locations/locationsList', {
        select: ['com_location.id AS id', 'com_location.title AS title'],
      })
    },
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: ['com_user.id AS id', 'com_user.name AS title'],
        where: {
          'com_user.status': 1,
        },
      })
    },
    searchAction() {
      this.actionMethods()
    },
    filterAction() {
      this.actionFilter()
    },
  },
}
</script>
<style scoped></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
