<template>
  <div>
    <div
      v-if="loading"
      class="mt-1"
    >
      <loading />
    </div>
    <template v-else>
      <template v-if="dataItem.id">
        <b-alert
          class="text-center"
          show
          variant="info"
        >
          <div class="alert-heading">
            Arayan Müşteri Bilgileri
          </div>
          <div class="alert-body">
            IP santralinize gelen çağrılar hakkında detaylı bilgi sağlayan bu sayfa, arayan müşterinizin bilgilerini hızlı bir şekilde görüntülemenize olanak tanır.
          </div>
        </b-alert>
        <customer-view />
      </template>
      <div v-else>
        <b-alert
          variant="danger"
          show
          class="text-center"
        >
          <div class="alert-heading">
            Arayan Müşteri Bilgileri
          </div>
          <div class="alert-body">
            <div>
              <span class="font-weight-bolder">{{ phone }}</span> numaralı telefon ile eşleşen müşteri kartı sistemde bulunamadı.
            </div>
            <div>
              Lütfen müşteri kartı oluşturun.
            </div>
            <div class="mt-1">
              <b-button
                variant="danger"
                pill
                :to="'/customers/add?phone=' + phone"
              >
                Müşteri Kartı Oluştur
              </b-button>
            </div>
          </div>
        </b-alert>
      </div>
    </template>
  </div>
</template>
<script>
import { BAlert, BButton } from 'bootstrap-vue'
import Loading from '@/layouts/components/Loading.vue'
import CustomerView from '@/views/Customers/CustomerView.vue'

export default {
  name: 'YeastarIndex',
  components: {
    BAlert,
    BButton,
    CustomerView,
    Loading,
  },
  computed: {
    dataItem() {
      return this.$store.getters['customers/getCustomer']
    },
    loading() {
      return this.$store.getters['customers/loading']
    },
    phone() {
      return this.$route.params.phone
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      let phone = this.$route.params.phone.trim().replace(/\D/g, '')
      if (phone[0] !== '0') {
        phone = `0${phone}`
      }
      this.$store.dispatch('customers/getData', {
        where: {
          'com_customer.phone': phone,
        },
        array: false,
      })
    },
  },
}
</script>
