<template>
  <div>
    <b-list-group-item>
      <div
        class="font-weight-bolder"
        :class="(dataType === 'answer')? 'text-primary' : 'text-success'"
      >
        <b-row>
          <b-col>
            <FeatherIcon
              v-if="dataType === 'answer'"
              icon="MessageCircleIcon"
            />
            <FeatherIcon
              v-if="dataType === 'solution'"
              icon="CheckCircleIcon"
            />
            {{ (dataType === 'answer')? 'Yanıt' : 'Çözüm Yanıtı' }}
          </b-col>
          <b-col cols="auto">
            <FeatherIcon
              v-b-tooltip.hover.v-primary.left
              :title="data.reach === '1'? 'Müşteriye Ulaşıldı' : 'Müşteriye Ulaşılamadı'"
              :class="data.reach === '1'? 'text-success' : 'text-danger'"
              :icon="data.reach === '1'? 'PhoneOutgoingIcon' : 'PhoneOffIcon'"
            />
          </b-col>
        </b-row>
      </div>
      <div v-html="data.content" />
      <div
        v-if="data.comment"
        class="bg-light-warning mt-1 p-1 rounded rounded-1"
      >
        {{ data.comment }}
      </div>
      <div
        v-if="data.rating >= 1"
        class="text-left mt-1"
      >
        <b-form-rating
          id="rating"
          v-model="data.rating"
          variant="warning"
          size="lg"
          class="m-0 p-0 bg-transparent"
          readonly
          no-border
          inline
        />
      </div>
      <hr>
      <b-row class="d-flex align-middle">
        <b-col cols="auto">
          <div class="text-info font-weight-bold">
            {{ data.username }}
          </div>
          <div class="text-muted">
            {{ moment(data.created).format('DD.MM.YYYY HH:mm') }}
          </div>
        </b-col>
        <b-col
          v-if="dataType === 'solution'"
          class="text-right text-muted"
        >
          <div>
            <b-badge
              v-if="data.status === '1'"
              variant="success"
            >
              Onaylandı
            </b-badge>
            <b-badge
              v-else-if="data.status === '0'"
              variant="danger"
            >
              Reddedildi
            </b-badge>
            <b-badge
              v-else
              variant="warning"
            >
              Onay Bekliyor
            </b-badge>
          </div>
          <div>
            {{ (data.modified)? data.approved_username + ' ' + moment(data.modified).format('DD.MM.YYYY HH:mm') : '' }}
          </div>
        </b-col>
      </b-row>
    </b-list-group-item>
    <b-list-group-item v-if="dataType === 'solution' && data.status === null">
      <confirm-buttons
        v-if="crmRequest.id_com_confirmation_user === userData.id"
        :id="data.id"
      />
      <denied-button
        v-else-if="deniedUsers.includes(userData.id_com_user_type)"
        :id="data.id"
      />
    </b-list-group-item>
  </div>
</template>
<script>
import {
  BListGroupItem, BRow, BCol, BBadge, VBTooltip, BFormRating,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import ConfirmButtons from '@/views/Crm/View/ConfirmButtons'
// eslint-disable-next-line import/extensions
import DeniedButton from '@/views/Crm/View/DeniedButton'

export default {
  name: 'AnswersItem',
  components: {
    BListGroupItem,
    BRow,
    BCol,
    BBadge,
    ConfirmButtons,
    DeniedButton,
    BFormRating,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      require: true,
    },
    dataType: {
      type: String,
      require: true,
      default: 'answer',
    },
  },
  data() {
    return {
      deniedUsers: ['1', '7', '8', '22'],
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    crmRequest() {
      return this.$store.getters['crmRequests/getRequest']
    },
  },
}
</script>

<style scoped>

</style>
