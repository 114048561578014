<template>
  <div>
    <b-tabs
      pills
      fill
    >
      <b-tab
        lazy
        active
      >
        <template #title>
          <feather-icon icon="PhoneIncomingIcon" />
          Gelen Arama
        </template>
        <yeastar-list-table call-type="Inbound" />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="PhoneOutgoingIcon" />
          Giden Arama
        </template>
        <yeastar-list-table call-type="Outbound" />
      </b-tab>
      <b-tab lazy>
        <template #title>
          <feather-icon icon="RepeatIcon" />
          Geri Arama
        </template>
        <yeastar-list-table call-type="Callback" />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import YeastarListTable from '@/views/Crm/YeastarIpCentral/Components/ListTable.vue'

export default {
  name: 'Index',
  components: {
    YeastarListTable,
    BTabs,
    BTab,
  },
}
</script>
