<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row class="d-flex justify-content-center align-items-center">
          <b-col>
            <yeastar-search-bar :filter-action="filterData" />
          </b-col>
        </b-row>
      </b-card-body>
      <template v-if="dataLoading">
        <loading />
      </template>
      <template v-else>
        <template v-if="dataList.length > 0">
          <b-table
            responsive="sm"
            :fields="fields"
            :items="dataList"
            striped
          >
            <template #cell(customer)="data">
              <template v-if="data.item.id_com_customer">
                {{ data.item.customer }}
              </template>
              <template v-else>
                <div class="text-danger">
                  {{ data.item.customer }}
                </div>
              </template>
              <div
                v-if="data.item.phone"
                class="mt-1"
              >
                <b-badge variant="light-info">
                  <feather-icon icon="PhoneIcon" />
                  {{ data.item.phone }}
                </b-badge>
              </div>
            </template>
            <template #cell(duration)="data">
              <feather-icon icon="ClockIcon" />
              {{ data.item.duration }} Sn.
            </template>
            <template #cell(disposition)="data">
              <template v-if="data.item.disposition === 'CEVAPLANDI'">
                <b-badge variant="light-success">
                  <feather-icon icon="CheckIcon" /> {{ data.item.disposition }}
                </b-badge>
              </template>
              <template v-else-if="data.item.disposition === 'CEVAPSIZ'">
                <b-badge variant="light-danger">
                  <feather-icon icon="XIcon" /> {{ data.item.disposition }}
                </b-badge>
              </template>
              <template v-else>
                <b-badge variant="light-warning">
                  <feather-icon icon="InfoIcon" /> {{ data.item.disposition }}
                </b-badge>
              </template>
            </template>
            <template #cell(datetime)="data">
              {{ moment(data.item.datetime).format('DD.MM.YYYY HH:mm:ss') }}
              <div
                v-if="data.item.duration"
                class="mt-1"
              >
                <b-badge variant="light-primary">
                  <feather-icon icon="ClockIcon" />
                  {{ data.item.duration }} Sn.
                </b-badge>
              </div>
            </template>
            <template #cell(control)="data">
              <div class="text-right">
                <b-button
                  variant="outline-primary"
                  size="sm"
                  @click="showModal(data.item.id)"
                >
                  Görüntüle
                </b-button>
              </div>
            </template>
          </b-table>
          <b-card-footer>
            <b-pagination
              v-model="currentPage"
              :total-rows="dataCounts"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-card-footer>
        </template>
        <b-alert
          v-else
          show
          variant="info"
          class="m-2"
        >
          <div class="alert-body text-center">
            Yeni görüşmeniz bulunmuyor.
          </div>
        </b-alert>
      </template>

    </b-card>
    <b-modal
      v-model="viewModal.status"
      title="Görüntüle"
      hide-footer
      centered
      no-close-on-esc
      no-close-on-backdrop
      size="xl"
    >
      <yeastar-view-modal
        :yeastar-id="viewModal.id"
        :call-type="callType"
      />
    </b-modal>
  </div>
</template>
<script>
import {
  BCard, BCardBody, BTable, BPagination, BButton, BCardFooter, BBadge, BRow, BCol, BAlert, BModal,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from '@/layouts/components/Loading.vue'
import YeastarViewModal from '@/views/Crm/YeastarIpCentral/Components/ViewModal.vue'
import YeastarSearchBar from '@/views/Crm/YeastarIpCentral/Components/SearchBar.vue'

const tableName = 'com_yeastar_calls'
export default {
  name: 'YeastarListTable',
  components: {
    YeastarSearchBar,
    YeastarViewModal,
    BAlert,
    Loading,
    BCard,
    BCardBody,
    BTable,
    BPagination,
    BButton,
    BCardFooter,
    BBadge,
    BRow,
    BCol,
    BModal,
  },
  props: {
    callType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
      perPage: 25,
      fields: [
        {
          key: 'customer',
          label: 'Müşteri',
        },
        // {
        //   key: 'phone',
        //   label: 'Telefon',
        //   thClass: 'width-200 text-nowrap',
        //   tdClass: 'width-200 text-nowrap',
        // },
        {
          key: 'datetime',
          label: 'Tarih Saat',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        // {
        //   key: 'duration',
        //   label: 'Süre',
        //   thClass: 'width-200 text-nowrap',
        //   tdClass: 'width-200 text-nowrap',
        // },
        {
          key: 'disposition',
          label: 'Durum',
          thClass: 'width-200 text-nowrap',
          tdClass: 'width-200 text-nowrap',
        },
        {
          key: 'control',
          label: '',
          thClass: 'width-100 text-nowrap',
          tdClass: 'width-100 text-nowrap',
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.srcname AS srcname`,
          `${tableName}.dstname AS dstname`,
          `${tableName}.src AS src`,
          `${tableName}.dst AS dst`,
          `${tableName}.datetime AS datetime`,
          `${tableName}.duration AS duration`,
          `${tableName}.disposition AS disposition`,
          `${tableName}.id_com_customer AS id_com_customer`,
        ],
        limit: 25,
        start: 0,
        callType: null,
        filterData: null,
      },

    }
  },
  computed: {

    dataList() {
      return this.$store.getters['yeastar/dataList']
    },
    dataCounts() {
      return this.$store.getters['yeastar/dataCounts']
    },
    saveData() {
      return this.$store.getters['yeastar/dataSaveStatus']
    },
    dataLoading() {
      return this.$store.getters['yeastar/dataLoading']
    },
    viewModal() {
      return this.$store.getters['yeastar/getViewModal']
    },
    filterParams() {
      return this.$store.getters['yeastar/getFilterData']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 25
      this.getDataList()
    },
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
      this.getDataList()
    },
  },
  created() {
    this.$store.commit('yeastar/RESET_VIEW_MODAL')
    this.filterData()
  },
  methods: {
    filterData() {
      const filterPar = {
        where: {},
        or_like: {},
      }
      if (this.filterParams.keywords) {
        filterPar.or_like = {
          'com_yeastar_calls.phone': this.filterParams.keywords,
          'com_customer.phone': this.filterParams.keywords,
          'com_customer.name': this.filterParams.keywords,
          'com_customer.company_name': this.filterParams.keywords,
        }
      }
      if (this.filterParams.sdate) {
        filterPar.where['date(com_yeastar_calls.datetime) >='] = this.filterParams.sdate
      }
      if (this.filterParams.edate) {
        filterPar.where['date(com_yeastar_calls.datetime) <='] = this.filterParams.edate
      }
      if (this.filterParams.disposition) {
        filterPar.where['com_yeastar_calls.disposition'] = this.filterParams.disposition
      }
      this.dataQuery.filterData = filterPar
      this.getDataList()
    },
    getDataList() {
      this.dataQuery.callType = this.callType
      this.$store.dispatch('yeastar/getCallList', this.dataQuery)
    },
    showModal(id) {
      this.viewModal.id = id
      this.viewModal.status = true
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
