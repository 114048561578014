<template>
  <div>
    <template v-if="savingStatus">
      <div class="mt-1">
        <loading />
      </div>
    </template>
    <template v-else>
      <validation-observer ref="interviewRules">
        <b-row>
          <b-col>
            <yeastar-interview-subjects-card />
          </b-col>
          <b-col>
            <yeastar-interview-status-card />
          </b-col>
          <b-col
            cols="12"
            md="12"
          >
            <yeastar-interview-content-card />
          </b-col>
          <b-col
            cols="12"
            md="12"
            class="text-right"
          >
            <b-button
              variant="primary"
              @click="closeCallRecord"
            >
              <feather-icon icon="CheckIcon" />
              Kaydı Kapat
            </b-button>
            <b-button
              variant="success"
              class="ml-1"
              @click="saveInterview"
            >
              <feather-icon icon="CheckSquareIcon" />
              Görüşmeyi Oluştur ve Kaydı Kapat
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </template>
  </div>
</template>
<script>
import { BRow, BCol, BButton } from 'bootstrap-vue'
import { ValidationObserver } from 'vee-validate'
import YeastarInterviewSubjectsCard from '@/views/Crm/YeastarIpCentral/Elements/Interview/InterviewSubject.vue'
import YeastarInterviewStatusCard from '@/views/Crm/YeastarIpCentral/Elements/Interview/InterviewStatus.vue'
import YeastarInterviewContentCard from '@/views/Crm/YeastarIpCentral/Elements/Interview/InterviewContentCard.vue'
import Loading from '@/layouts/components/Loading.vue'

export default {
  name: 'YeastarInterviewCard',
  components: {
    Loading,
    ValidationObserver,
    YeastarInterviewContentCard,
    YeastarInterviewStatusCard,
    YeastarInterviewSubjectsCard,
    BRow,
    BCol,
    BButton,
  },
  props: {
    callType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      savingStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['yeastar/dataItem']
    },
    customerData() {
      return this.$store.getters['customers/getCustomer']
    },
    interviewData() {
      return this.$store.getters['yeastar/getInterviewData']
    },
    modalData() {
      return this.$store.getters['yeastar/getViewModal']
    },
  },
  created() {
    this.$store.commit('yeastar/RESET_INTERVIEW_DATA')
  },
  methods: {
    saveInterview() {
      this.$refs.interviewRules.validate().then(success => {
        if (success) {
          this.savingStatus = true
          if (this.callType === 'Inbound') {
            this.interviewData.id_com_meet = 59
          }
          if (this.callType === 'Outbound') {
            this.interviewData.id_com_meet = 58
          }
          if (this.callType === 'Callback') {
            this.interviewData.id_com_meet = 58
          }
          this.interviewData.id_com_customer = this.customerData.id
          this.interviewData.id_com_yeastar = this.dataItem.id
          this.$store.dispatch('yeastar/saveInterview', this.interviewData)
            .then(response => {
              if (response.status) {
                this.modalData.status = false
              }
            })
        }
      })
    },
    closeCallRecord() {
      this.savingStatus = true
      const saveArr = {
        id: this.dataItem.id,
        id_com_customer: this.customerData.id,
        close_date: this.moment().format('YYYY-MM-DD H:mm:ss'), // Şu anki tarih ve saat
      }
      this.$store.dispatch('yeastar/saveData', saveArr).then(response => {
        if (response.status) {
          this.modalData.status = false
        }
      })
    },
  },
}
</script>
